/**
 * @name Button
 * @description 버튼
 */
import React from 'react'
import {COLOR} from 'styles/color'
import styled from 'styled-components'
import {CSS} from 'types'
import {Text} from 'lib'

type Props = {
  params?: {}
  className?: string //---------------------------클래스명
  label?: string //-------------------------------버튼명
  style?: CSS //--------------------------------- 스타일시트
  color?: string //------------------------------ 텍스트색상
  bgcolor?: string //---------------------------- 배경색상
  children?: React.ReactNode //------------------ 자식노드
  isshadow?: string //-------------------------- 그림자
  onClick?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
}
export default function NextButton({className, isshadow = 'true', style, color, bgcolor, label, onClick, children}: Props) {
  return (
    <Element
      isshadow={isshadow}
      style={style}
      bgcolor={bgcolor}
      className={className}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        !!onClick && onClick(event)
      }}>
      <Text mode="Headline_SB" label={label} color={color || COLOR.white} />
      {children}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.button<Props>`
  display: block;
  width: 100%;
  height: 94px;
  border-radius: 16px;
  background: ${({bgcolor}) => bgcolor || COLOR.blue30};
  box-shadow: ${({isshadow}) => (isshadow === 'true' ? `0px 11.25px 16.875px 0px rgba(24, 59, 86, 0.12)` : 'none')};
  /* box-shadow: 0px 11.25px 16.875px 0px rgba(24, 59, 86, 0.12); */
  &:outline {
    border: none;
  }
`
/********************************************************
[사용법]



*********************************************************/
