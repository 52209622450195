/**
 * @name 충전할커넥터방향을선택해주세요.
 * @description
 */
import {useEffect} from 'react'
import {Image, Text, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {DELAY_TIME, MENU} from 'config'
import {select_member_tag_img1} from 'assets/images'
// contents

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  //*--------------------------------------------------*
  useEffect(() => {
    setTimeout(() => {
      navigate(MENU.SELECT_MEMBER_TAG_CHECK)
    }, DELAY_TIME)
  }, [])
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: true, title: '회원 카드 태그', home: true}}>
      <Content>
        <Text style={{marginTop: 30}} align="center" mode="Body_LLR" label="회원 카드를 태그 해주세요." />
        <div style={{width: 482, marginTop: 50, marginLeft: 'auto', marginRight: 'auto'}}>
          <Image src={select_member_tag_img1} width={482} />
        </div>
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 768px;
  margin: 24px auto;
  text-align: center;
`
