/**
 * @name 키패드타이틀
 * @description
 */
import {checked, checked_on} from 'assets/svg'
import {Flex, Image, Loop, Text} from 'lib'
import {useAppDispatch, useAppSelector} from 'model'
import {contextAction} from 'model/modules'
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {ListRenderItem} from 'types'

export default function Index() {
  // hooks
  const dispatch = useAppDispatch()
  const [isPassword, setIsPassword] = useState<boolean>(false)
  const {nonMemberPhone} = useAppSelector(state => state.context)
  const [phone, setPhone] = useState<string>('010-0000-0000')
  const [phoneTitle, setPhoneTitle] = useState<string>(phone)
  const key = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '초기화', value: 'reset'},
    {label: '0', value: 0},
    {label: '지움', value: 'back'},
  ]
  const formatPhoneNumber = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '')
    if (numbers.length < 4) return numbers
    if (numbers.length < 7) return `${numbers.slice(0, 3)}-${numbers.slice(3)}`
    return `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}-${numbers.slice(7, 11)}`
  }
  const keypadFunc = (value: any) => {
    let currentNumber = phone.replace(/-/g, '')
    if (phone === '010-0000-0000') {
      currentNumber = ''
    }
    if (value === 'back') {
      currentNumber = currentNumber.slice(0, -1)
      if (currentNumber === '') {
        currentNumber = '010-0000-0000'
      }
    } else if (value === 'reset') {
      setPhone('010-0000-0000')
      setIsPassword(false)
      return
    } else {
      if (currentNumber.length < 11) {
        currentNumber += value
      } else {
        return // 추가 입력 무시
      }
    }
    setPhone(formatPhoneNumber(currentNumber))
    dispatch(contextAction.setPhone(formatPhoneNumber(currentNumber)))
  }
  useEffect(() => {
    if (isPassword) {
      const maskedNumber = phone
        .split('-')
        .map((part, index) => (index === 0 ? part : part.replace(/\d/g, '*')))
        .join('-')
      setPhoneTitle(maskedNumber)
    } else {
      setPhoneTitle(phone)
    }
  }, [phone, isPassword])

  //*--------------------------------------------------*
  return (
    <Content>
      <Flex style={{width: '100%', marginTop: 30}} flexdirection="column" alignitems="flex-start" justifycontent="center">
        {/* 출력값 */}
        <Flex className="amount" alignitems="flex-end" justifycontent="flex-end">
          <Text mode="Headline_SB" color={phoneTitle === '' || phoneTitle === '010-0000-0000' ? COLOR.gs40 : COLOR.gs10} label={phoneTitle} />
        </Flex>
      </Flex>
      <Flex.SpaceBetween style={{marginBottom: 40}}>
        <Text mode="Caption_LR" color={COLOR.gs40} label={'전화번호를 입력해 주세요.'} />
        <button
          style={{display: 'flex', alignItems: 'center'}}
          onClick={() => {
            setIsPassword(val => !val)
          }}>
          <Image src={isPassword ? checked_on : checked} style={{marginRight: 8}} />
          <Text mode="Body_SSB" color={COLOR.gs30} label="회원번호 숨기기" />
        </button>
      </Flex.SpaceBetween>
      <div className="key-wrapper">
        <Loop
          info={key}
          renderItem={({item}: ListRenderItem) => {
            return (
              <button
                className="item"
                onClick={() => {
                  keypadFunc(item.value)
                }}>
                <span className="label">{item.label}</span>
                {item?.sub && <span className="sub">{item.sub}</span>}
              </button>
            )
          }}
        />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  .amount {
    width: 100%;
    margin-bottom: 24px;
    border-bottom: 1px solid ${COLOR.gs40};
  }
  .key-wrapper {
    display: grid;
    width: 592px;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    /* grid-gap: 16px; */
    grid-row-gap: 8px;
    grid-column-gap: 11px;
    /* 버튼영역,키패드 */
    .item {
      /* width: 192px; */
      height: 76px;
      border-radius: 24px;
      color: ${COLOR.gs10};
      background-color: ${COLOR.blue50};
      outline: none;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 60px */
      letter-spacing: -0.4px;
      -webkit-tap-highlight-color: transparent; /* for removing the highlight */
      .sub {
        display: inline-block;
        margin-left: 5px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
        letter-spacing: -0.4px;
      }
      &:active {
        color: ${COLOR.white} !important;
        background-color: ${COLOR.blue30};
      }
    }
  }
`
