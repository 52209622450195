/**
 * @name 충전할커넥터방향을선택해주세요.
 * @description
 */
import {useEffect} from 'react'
import {Flex, Image} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {COLOR} from 'styles'
import {useAppSelector} from 'model'
import {charge_direction_img1} from 'assets/images'

// contents
import LeftChargeOff from './contents/left_charge_off'
import LeftChargeOn from './contents/left_charge_on'
import RightChargeOff from './contents/right_charge_off'
import RightChargeOn from './contents/right_charge_on'

export default function Index() {
  // hooks
  const {ischarge_left, ischarge_right} = useAppSelector(state => state.context.charge_status)
  useEffect(() => {}, [])
  // hooks
  //*--------------------------------------------------*
  return (
    <App bgcolor={COLOR.blue30}>
      <Content>
        <Image style={{marginTop: 30, marginBottom: 30}} src={charge_direction_img1} width={678} />
        <Flex className="wrapper" alignitems="center" justifycontent="space-between">
          {/* 왼쪽커넥터 */}
          {!ischarge_left && <LeftChargeOff />}
          {ischarge_left && <LeftChargeOn />}
          <div style={{width: 20}} />
          {/* 오른쪽커넥터 */}
          {!ischarge_right && <RightChargeOff />}
          {ischarge_right && <RightChargeOn />}
        </Flex>
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 768px;
  margin: 0 auto;
  padding: 0 40px;
  /* wrapper */
  .wrapper {
    width: 678px;
    height: 552px;
  }
  .button {
  }
`
