/**
 * @name 충전금액입력
 * @description
 */
import {useEffect} from 'react'
import {Flex, Image, Text, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {pay_card} from 'assets/svg'
// contents
import {COLOR} from 'styles'
import {DELAY_TIME, MENU} from 'config'

export default function Index() {
  // hooks
  const {navigate} = useRoutes()

  //*--------------------------------------------------*
  useEffect(() => {
    //충전완료
    const timer = setTimeout(() => {
      navigate(MENU.CHARGE_PAY_PRECESS)
    }, DELAY_TIME)
    // 클린업 함수
    return () => {
      clearTimeout(timer)
    }
  }, [])
  // hooks
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: true, title: '완전충전(50,000원) 결제', home: true}}>
      <Content>
        <Flex flexdirection="column" alignitems="center" justifycontent="center">
          <Text style={{marginTop: 50, marginBottom: 80}} mode="Body_SB" color={COLOR.gs10}>
            <p style={{textAlign: 'center'}}>
              완충 후 결제 차액은 자동으로 부분 취소됩니다.
              <br /> 신용카드를 IC 리더기에 입력해 주세요.
            </p>
          </Text>
          <Image src={pay_card} width={564} style={{marginTop: 0}} />
        </Flex>

        {/* 확인 */}
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 768px;
  margin: 0 auto;
`
