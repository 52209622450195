/**
 * @name 충전금액입력
 * @description
 */
import {useEffect} from 'react'
import {Events, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {MENU} from 'config'
// contents
import Title from './contents/title'
import KeyPad from './contents/key-pad'
import {NextButton} from 'pages/common/components'
import {useAppSelector} from 'model'
import {COLOR} from 'styles'
import {CONTEXT_EVENT} from 'model/modules'

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  const {nonMemberPassword} = useAppSelector(state => state.context)

  //*--------------------------------------------------*
  const isValidate = () => {
    if (nonMemberPassword === '0000') return false
    return nonMemberPassword.length === 4 ? true : false
  }
  useEffect(() => {}, [])
  // hooks
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: true, title: '비밀번호 생성', home: true}}>
      <Content>
        {/* 타이틀 */}
        <Title />
        {/* 키패드 */}
        <KeyPad />
        {/* <Image src={charge_amount_img1} width={830} style={{marginTop: 24}} /> */}
        {/* 확인 */}
        <NextButton
          className={'auto'}
          bgcolor={isValidate() ? COLOR.blue30 : COLOR.gs40}
          style={{width: 541, marginTop: 50}}
          label="확인"
          onClick={() => {
            if (isValidate()) {
              navigate(MENU.CHARGE_TYPE)
            } else {
              Events.emit(CONTEXT_EVENT.TOAST, '비밀번호 4자리를 입력해 주세요.')
            }
          }}
        />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 768px;
  margin: 0 auto;
  padding-left: 90px;
  padding-right: 90px;
`
