/**
 * @name 충전현황>진행중
 * @description
 */
import {LIMIT_TIME, MENU} from 'config'
import {Flex, Text, useContext, useRoutes} from 'lib'
import {useAppDispatch, useAppSelector} from 'model'
import {contextAction} from 'model/modules'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {COLOR} from 'styles'

export default function Index() {
  // hooks
  const location = useLocation() as any
  const dispatch = useAppDispatch()
  const {navigate} = useRoutes()
  const {charge_status} = useAppSelector(state => state.context)
  const {selectDevice, leftDevice, rightDevice} = charge_status
  //*--------------------------------------------------*
  // 남은시간계산
  const setRemainTime = () => {
    const time = selectDevice === 'left' ? leftDevice : rightDevice
    const result = LIMIT_TIME - time
    if (time >= LIMIT_TIME - 1) {
      navigate(MENU.CHARGE_COMPLETE)
      return
    }
    return result.toString()
  }
  // 진행중인프로세스
  const setProress = () => {
    const time = selectDevice === 'left' ? leftDevice : rightDevice
    const result = Math.floor((time / LIMIT_TIME) * 100)
    return result.toString()
  }
  //*--------------------------------------------------*
  useEffect(() => {
    // console.log('selectDevice :' + selectDevice)
    // console.log(location?.state?.mode)
    let temp
    // 왼쪽 (연속성있으면 해당타임으로)
    if (selectDevice === 'left') {
      temp = {ischarge_left: true, leftDevice: location?.state?.mode === 'continue' ? leftDevice : 0}
    }
    // 오른쪽  (연속성있으면 해당타임으로)
    if (selectDevice === 'right') {
      temp = {ischarge_right: true, rightDevice: location?.state?.mode === 'continue' ? rightDevice : 0}
    }
    dispatch(contextAction.setChargeStatus(temp))
    return () => {}
  }, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex style={{width: '100%', marginTop: 33}} alignitems="center" justifycontent="space-around">
        {/* 남은시간 */}
        <Flex flexdirection="column" alignitems="flex-start">
          <Flex alignitems="center">
            <Text mode="Button_SB" color={COLOR.gs30} label="남은 시간" style={{marginRight: 20}} />
            <Text mode="Display_SB" color={COLOR.blue30} label={setRemainTime()} style={{width: 80}} />
            <Text mode="Body_SR" color={COLOR.gs10} label="분" style={{marginLeft: 8, marginTop: 20}} />
          </Flex>
          {/* 프로그래스바 */}
          <button
            className="bg"
            onClick={() => {
              navigate(MENU.CHARGE_COMPLETE)
            }}>
            <div className="progress" style={{width: setProress() + '%'}} />
          </button>
        </Flex>
        {/* XX% */}
        <Flex alignitems="center" style={{width: 170}}>
          <Text mode="Display_MB" color={COLOR.blue30} label={setProress()} />
          <Text mode="Title_LB" color={COLOR.blue30} label={'%'} style={{marginTop: 70}} />
        </Flex>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  /* 프로그래스바 */
  .bg {
    width: 450px;
    height: 32px;
    overflow: hidden;
    border-radius: 99px;
    background-color: ${COLOR.gs40};
    .progress {
      transition: all 0.5s ease-in-out;
      height: 31px;
      background-color: ${COLOR.green10};
    }
  }
`
