/**
 * @name useToast
 * @description 토스트팝업
 */

import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {AnimatePresence, motion} from 'framer-motion'
import {COLOR} from 'styles'
import {IntervalRef} from 'types'

type Props = {
  time?: number //-------------------------------- 보여지는시간
  message?: string //----------------------------- 메세지
  children?: React.ReactNode //--------------------Children
}
export const useToast = ({time, message, children}: Props) => {
  // hooks
  const itvRef = useRef(0) as IntervalRef
  const [visible, setVisible] = useState(false)
  // *-------------------------------------------------------------------*
  /**
   * @name 토스트팝업엘리먼트생성
   */
  const createElement = () => {
    return <article className="item">{message ?? '메세지'}</article>
  }
  /**
   * @name 팝업보여주기
   */
  const show = () => {
    const _time = time ?? 3000
    clearTimeout(itvRef.current)
    setVisible(true)
    itvRef.current = setTimeout(() => {
      setVisible(false)
    }, _time)
  }
  /**
   * @name 캘린더생성
   */
  const toast = () => {
    return (
      <Element>
        <AnimatePresence initial={false}>
          {visible && (
            <Motion key="modal" initial={{y: 0, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{opacity: 0}}>
              {children ?? createElement()}
            </Motion>
          )}
        </AnimatePresence>
      </Element>
    )
  }
  return {toast, show}
}
//*--------------------------------------------------*
const Element = styled.div`
  position: fixed;
  z-index: 10;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  /* background: ${COLOR.BLACK1}; */
  /* 기본아이템 */
`
const Motion = styled(motion.div)`
  /* 아이템이 있을경우,기본아이템 */
  .item {
    display: flex;
    width: 230px;
    height: 30px;
    /* padding: 16px 24px; */
    color: ${COLOR.white};
    background: ${COLOR.BLACK1};
    border-radius: 4px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    font-size: 15px;
    line-height: 24px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  }
`
/********************************************************
[사용법]

// custom
const {toast, show} = useToast({time: 768, children: <div>토스트팝업</div>})
// message
const {toast, show} = useToast({time: 768, message: '확인되었습니다.'})
*********************************************************/
