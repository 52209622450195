/**
 * @name 출력용량안내
 * @description
 */
import {useEffect, useState} from 'react'
import {checked, checked_on} from 'assets/svg'
import {Events, Flex, Image, Text, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {MENU} from 'config'
import {COLOR} from 'styles'
import {CONTEXT_EVENT, contextAction} from 'model/modules'
import {useAppDispatch, useAppSelector} from 'model'
// contents
import {NextButton} from 'pages/common/components'
import {output_capacity_img1} from 'assets/images'

export default function Index() {
  // hooks
  const dispatch = useAppDispatch()
  const {navigate} = useRoutes()
  const {isCheck} = useAppSelector(state => state.context)
  const [isChecked, setIsChecked] = useState<boolean>(isCheck)
  // hooks
  useEffect(() => {
    // 전역컨텍스트에 체크여부False
    if (isChecked) {
      dispatch(contextAction.setIsCheck(false))
    }
  }, [])
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: true, title: '출력 용량 안내', home: false}}>
      <Content>
        <Flex alignitems="center" justifycontent="center">
          <Image src={output_capacity_img1} width={678} />
        </Flex>
        {/* 확인 */}
        <button
          className="check-button"
          onClick={() => {
            // 전역컨텍스트에 체크여부False
            if (isChecked) {
              dispatch(contextAction.setIsCheck(false))
            }
            setIsChecked(val => !val)
          }}>
          <Image src={isChecked ? checked_on : checked} style={{marginRight: 8}} />
          <Text mode="Body_SSM" color={COLOR.gs30} label="상기 내용을 확인하였습니다." />
        </button>
        {/* 체크표시 */}
        <NextButton
          className={'auto'}
          bgcolor={isChecked ? COLOR.blue30 : COLOR.gs40}
          style={{width: 453, marginTop: 40}}
          label="동의하고 계속하기"
          onClick={() => {
            if (isChecked) {
              dispatch(contextAction.setIsCheck(true))
              navigate(MENU.SELECT_MEMBER)
            } else {
              Events.emit(CONTEXT_EVENT.TOAST, '상기 내용 확인의 체크 박스를 선택해주세요.')
              // alert('동의하고 계속하기 체크해주세요.')
            }
          }}
        />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /* width: 950px; */
  margin: 24px auto 0;
  .check-button {
    display: flex;
    width: 100%;
    margin-top: 32px;
    justify-content: center;
    align-items: center;
    outline: none;
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
  }
`
