/**
 * @name .
 * @description
 */
import {Flex, Image, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {charge_pay_complete} from 'assets/svg'
import {MENU} from 'config'
import {NextButton} from 'pages/common/components'
import {COLOR} from 'styles'
// contents

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  // hooks
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: false, title: '결제완료', home: false}}>
      <Content>
        <Flex flexdirection="column" justifycontent="center" alignitems="center">
          <Image src={charge_pay_complete} width={500} style={{marginLeft: -140}} />
        </Flex>
        {/* 체크표시 */}
        <NextButton
          className={'auto'}
          bgcolor={COLOR.blue30}
          style={{width: 678, marginTop: 200}}
          label="확인"
          onClick={() => {
            navigate(MENU.CHARGE_WAIT)
          }}
        />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 768px;
  margin: 50px auto;
  text-align: center;
`
