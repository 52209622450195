/**
 * @name 사용방법및유의사항
 * @description
 */
import {useState} from 'react'
import {Button, Events, Flex, Image, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {notice_img1, notice_img2} from 'assets/svg'
import {COLOR} from 'styles'
// contents
import {NextButton} from 'pages/common/components'
import {CONTEXT_EVENT} from 'model/modules'

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  const [isActive, setIsActive] = useState(false)
  //*--------------------------------------------------*
  return (
    <App bgcolor={COLOR.white}>
      <Content>
        <div className="scroll">
          <Image src={notice_img1} width={678} />
        </div>
        <Flex alignitems="center" justifycontent="center">
          <Image src={notice_img2} width={500} />
        </Flex>
        {/* 확인 */}
        <NextButton
          className={'auto'}
          style={{marginTop: 50, width: 678}}
          label="확인"
          onClick={() => {
            navigate(-1)
          }}
        />
        {/* 설정모드 */}
        <button
          className="active"
          onClick={() => {
            alert(`관리자모드 실행합니다.\n\"닫기" 를 눌러주세요.`)
            setIsActive(!isActive)
            // console.log('설정모드')
          }}>
          &nbsp;
        </button>
        {isActive && (
          <article className="popup">
            <Flex.Column alignitems="center" justifycontent="center" style={{height: '100%'}}>
              <Button
                className="contextBtn"
                label="새로고침"
                full
                style={{backgroundColor: COLOR.red10}}
                onClick={() => {
                  window.location.reload()
                }}
              />
              <Button
                className="contextBtn"
                label="풀스크린 실행"
                full
                onClick={() => {
                  Events.emit(CONTEXT_EVENT.DISPLAY_FULL_SCREEN)
                }}
              />
              <Button
                className="contextBtn"
                label="풀스크린 중지"
                full
                onClick={() => {
                  Events.emit(CONTEXT_EVENT.DISPLAY_NORMAL_SCREEN)
                }}
              />
              <Button
                style={{backgroundColor: COLOR.green10}}
                className="contextBtn"
                label="닫기"
                full
                onClick={() => {
                  setIsActive(false)
                }}
              />
            </Flex.Column>
          </article>
        )}
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /*---------- 컨텐츠 --------*/
  .scroll {
    width: 678px;
    height: 600px;
    margin: 32px auto 32px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  /*---------- 설정모드 --------*/
  .active {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    width: 250px;
    height: 100px;
    background: #000000;
    opacity: 0;
  }
  .popup {
    position: absolute;
    z-index: 20;
    top: 200px;
    left: 100;
    right: 100;
    width: 100%;
    height: 100%;
    padding: 0 100px;
    background-color: rgba(0, 0, 0, 0.8);
    /* opacity: 0.8; */
    .contextBtn {
      position: relative;
      z-index: 30;
      margin: 30px;
      height: 100px;
      border-radius: 20px;
      font-size: 30px;
      font-weight: normal;
    }
  }
`
