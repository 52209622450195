/**
 * @name 충전금액입력
 * @description
 */
import {useEffect, useState} from 'react'
import {Events, Image, Text, debug, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {MENU} from 'config'
import {COLOR} from 'styles'
import {CONTEXT_EVENT, contextAction} from 'model/modules'
import {checked, checked_on} from 'assets/svg'
import {useAppDispatch, useAppSelector} from 'model'
// contents
import Title from './contents/title'
import KeyPad from './contents/key-pad'
import {NextButton} from 'pages/common/components'

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const {nonMemberPhone} = useAppSelector(state => state.context)
  //*--------------------------------------------------*
  const isValidate = () => {
    if (nonMemberPhone === '010-0000-0000') return false
    const _length = nonMemberPhone.replace(/[^0-9]/g, '').length
    return (_length === 10 || _length === 11) && isChecked
  }
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: true, title: '휴대전화 번호 입력', home: true}}>
      <Content>
        {/* 타이틀 */}
        <Title />
        {/* 키패드 */}
        <KeyPad />
        {/* 개인정보처리방침 */}
        <button
          className="check-button"
          onClick={() => {
            setIsChecked(val => !val)
          }}>
          <Image src={isChecked ? checked_on : checked} style={{marginRight: 8}} />
          <Text mode="Body_SB" color={COLOR.blue30} label="개인정보처리방침">
            <Text mode="Body_SB" color={COLOR.gs30} label="에 동의합니다." />
          </Text>
        </button>
        {/* 확인 */}
        {/* {debug(nonMemberPhone)} */}
        <NextButton
          className={'auto'}
          bgcolor={isValidate() ? COLOR.blue30 : COLOR.gs40}
          style={{width: 541, marginTop: 30}}
          label="동의하고 계속하기"
          onClick={() => {
            if (isValidate()) {
              navigate(MENU.NON_MEMBER_PASSWORD)
            } else {
              Events.emit(CONTEXT_EVENT.TOAST, '개인정보처리방침의 체크 박스를 선택 및 휴대전화 번호를 확인 해주세요.')
            }
          }}
        />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 768px;
  margin: 0 auto;
  padding-left: 90px;
  padding-right: 90px;
  .check-button {
    display: flex;
    width: 100%;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    outline: none;
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
  }
`
