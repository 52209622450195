/**
 * @name 충전금액입력
 * @description
 */
import {useEffect} from 'react'
import {Flex, Text, addComma, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {MENU} from 'config'
import {useAppDispatch, useAppSelector} from 'model'
import {contextAction} from 'model/modules'
import {COLOR} from 'styles'
// contents
import {NextButton} from 'pages/common/components'

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  const dispatch = useAppDispatch()
  const {result} = useAppSelector(state => state.context)
  //*--------------------------------------------------*
  useEffect(() => {
    //충전완료
    dispatch(contextAction.setChargeStatus({selectDevice: null}))
    const timer = setTimeout(() => {
      navigate(MENU.CHARGE_DIRECTION)
    }, 768 * 60)
    // 클린업 함수
    return () => {
      clearTimeout(timer)
    }
  }, [])
  // hooks
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: false, title: '충전 완료', home: false}}>
      <Content>
        <Flex flexdirection="column" alignitems="center" justifycontent="center" style={{marginTop: 56}}>
          <Text style={{marginBottom: 65}} mode="Body_MR" align="center" color={COLOR.gs10}>
            충전이 완료되었습니다.
            <br />
            커넥터를 차량에서 분리하여, 충전기 커넥터 합에 거치해 주세요.
          </Text>
          <section className="info">
            <Flex.SpaceBetween width="100%" style={{marginBottom: 10}}>
              <Text mode="Button_SB" label="총 충전 금액" color={COLOR.blue30} />
              <Text mode="Button_SB" label={`${addComma(result?.price)} 원`} color={COLOR.blue30} />
            </Flex.SpaceBetween>
            <Flex.SpaceBetween width="100%" style={{marginBottom: 10}}>
              <Text mode="Button_SB" label="충전량" color={COLOR.blue10} />
              <Text mode="Button_SB" label={`${addComma(result?.kwh)} kWh`} color={COLOR.blue10} />
            </Flex.SpaceBetween>
            <Flex.SpaceBetween width="100%">
              <Text mode="Button_SB" label="충전 소요시간" color={COLOR.blue10} />
              <Text mode="Button_SB" label={`1시간 00분`} color={COLOR.blue10} />
            </Flex.SpaceBetween>
          </section>
          <Text mode="Body_MSB" color={COLOR.red10} align="center">
            60초 후 초기 화면으로 이동합니다.
          </Text>
        </Flex>
        {/* 확인 */}
        <NextButton
          className={'auto'}
          style={{width: 678, marginTop: 80}}
          label="확인"
          onClick={() => {
            dispatch(contextAction.setInit())
            navigate(MENU.CHARGE_DIRECTION)
          }}
        />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 768px;
  margin: 0 auto;
  /* section */
  .info {
    width: 678px;
    margin-bottom: 60px;
    padding: 60px;
    border-radius: 40px;
    border: 2px solid ${COLOR.gs50};
    background: ${COLOR.white};
    align-items: center;
    gap: 24px;
  }
`
