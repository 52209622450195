/**
 * @name .
 * @description
 */
import {useEffect} from 'react'
import {Flex, Image, Text, useRoutes} from 'lib'
import styled from 'styled-components'
import {App, Loading} from 'template'
import {charge_pay_process} from 'assets/svg'
import {DELAY_TIME, MENU} from 'config'
// contents

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  //*--------------------------------------------------*
  useEffect(() => {
    setTimeout(() => {
      navigate(MENU.CHARGE_PAY_COMPLETE)
    }, DELAY_TIME)
  }, [])
  // hooks
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: false, title: '결제 진행중', home: false}}>
      <Content>
        <Flex flexdirection="column" justifycontent="center" alignitems="center">
          <Text style={{marginTop: 30}} align="center" mode="Headline_SB" label="결제가 진행 중입니다." />
          <Text style={{marginTop: 30}} align="center" mode="Body_SB" label="잠시만 기다려 주세요." />
          {/* <Image src={charge_pay_process} style={{marginLeft: -200}} /> */}
          <div style={{marginTop: 100}}>
            <Loading />
          </div>
        </Flex>
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 768px;
  margin: 50px auto;
`
