/**
 * @name Root
 */
import {Route, Routes, BrowserRouter} from 'react-router-dom'
import {MenuRoutes} from 'config'
import {Events, uuid} from 'lib'
import {MenuRoutesType} from 'types'
import {FullScreen, useFullScreenHandle} from 'react-full-screen'
import {useInterval} from 'lib'
import {useAppDispatch, useAppSelector} from 'model'
import {CONTEXT_EVENT, contextAction} from 'model/modules'
// contents
import {useEffect} from 'react'

export default function Router() {
  // initalize
  const {charge_status} = useAppSelector(state => state.context)
  const dispatch = useAppDispatch()
  const handle = useFullScreenHandle()
  //*--------------------------------------------------*
  // ticker
  useInterval(() => {
    dispatch(
      contextAction.setChargeStatus({
        leftDevice: charge_status?.leftDevice + 1,
        rightDevice: charge_status?.rightDevice + 1,
      }),
    )
  }, 1000)
  // 풀스크린토글
  const onFullScreen = () => {
    handle.enter()
  }
  const onNormalScreen = () => {
    handle.exit()
  }
  //*--------------------------------------------------*
  useEffect(() => {
    // addListener
    Events.addListener(CONTEXT_EVENT.DISPLAY_FULL_SCREEN, onFullScreen)
    Events.addListener(CONTEXT_EVENT.DISPLAY_NORMAL_SCREEN, onNormalScreen)
    return () => {
      // removeListener
      Events.removeListener(CONTEXT_EVENT.DISPLAY_FULL_SCREEN, onFullScreen)
      Events.removeListener(CONTEXT_EVENT.DISPLAY_NORMAL_SCREEN, onNormalScreen)
    }
  }, [])
  //*--------------------------------------------------*
  return (
    <FullScreen handle={handle}>
      {/* 광고영역 */}
      {/* <Ad /> */}
      {/* 라우팅영역 */}
      <BrowserRouter basename="/">
        <Routes>
          {MenuRoutes.map((item: MenuRoutesType) => {
            return <Route key={uuid()} path={item.path} element={item.element} />
          })}
        </Routes>
      </BrowserRouter>
    </FullScreen>
  )
}
/*===================================================================[계정관리] */
/*
[메뉴추가]

/src/config/menu.ts
  - MENU에 메뉴추가

/src/config/menu-routes.ts
  - MenuRoutes에 path 설정및 element 연결
*/
