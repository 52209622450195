/**
 * @name 왼쪽커넥터
 * @description
 */
import {CountUp, Flex, Image, Text, randomNumber, useRoutes} from 'lib'
import styled from 'styled-components'
import {MENU} from 'config'
import {COLOR} from 'styles'
import {useAppDispatch, useAppSelector} from 'model'
import {left_arrow} from 'assets/svg'
import {contextAction} from 'model/modules'
// contents
import {PanelButton} from 'pages/common/components'

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  const dispatch = useAppDispatch()
  const {member_price, non_member_price} = useAppSelector(state => state.context)
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <PanelButton
        className="button"
        onClick={() => {
          //출력 용량 안내
          dispatch(contextAction.setChargeStatus({selectDevice: 'left'}))
          navigate(MENU.OUTPUT_CAPACITY)
        }}>
        <Flex className="group" flexdirection="column" alignitems="flex-start" justifycontent="flex-start">
          <Text mode="Headline_MB" label="왼쪽 커넥터" color={COLOR.blue30} />
          {/* 텍스트 */}
          <Text mode="Caption_LR" color={COLOR.blue30} style={{marginTop: 30}}>
            <span>회원 </span>
            <CountUp from={Number(member_price) - randomNumber(-150, 150)} to={Number(member_price)} />
            <span> 원/kWh</span>
          </Text>
          <Text mode="Caption_LR" color={COLOR.blue30}>
            <span>비회원 </span>
            <CountUp from={Number(non_member_price) - randomNumber(-150, 150)} to={Number(non_member_price)} />
            <span> 원/kWh</span>
          </Text>
          <Image src={left_arrow} style={{marginLeft: -80}} />
          <Text mode="Body_MR" style={{width: '100%', textAlign: 'center'}} label="왼쪽 커넥터로 충전하기" color={COLOR.blue30} />
        </Flex>
      </PanelButton>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  flex: 1;
  /* 버튼프로젝트 */
  .button {
    width: 324px;
    height: 552px;
    padding: 60px 0 50px 30px;
  }
`
