/**
 * @name .
 * @description
 */
import {useEffect} from 'react'
import {Flex, Text, useRoutes} from 'lib'
import styled from 'styled-components'
import {App, Loading} from 'template'
import {DELAY_TIME, MENU} from 'config'
// contents

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  //*--------------------------------------------------*
  useEffect(() => {
    setTimeout(() => {
      navigate(MENU.CHARGE_TYPE)
    }, DELAY_TIME)
  }, [])
  // hooks
  //*--------------------------------------------------*
  return (
    <App>
      <Content>
        <Flex flexdirection="column" justifycontent="center" alignitems="center">
          <Text style={{marginTop: 30}} align="center" mode="Headline_SB" label="회원번호를 확인중입니다." />
          <div style={{marginTop: 40, width: 482, marginLeft: 'auto', marginRight: 'auto'}}>
            <Text align="center" mode="Body_LR" label="잠시만 기다려 주세요." />
            <Loading />
          </div>
        </Flex>
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 768px;
  margin: 134px auto;
  text-align: center;
`
