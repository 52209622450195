/**
 * @name 충전량입력
 * @description
 */
import {useEffect} from 'react'
import {useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {MENU} from 'config'
// contents
import KeyPad from './contents/key-pad'
import {NextButton} from 'pages/common/components'

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  useEffect(() => {}, [])
  // hooks
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: true, title: '충전량입력', home: true}}>
      <Content>
        {/* 키패드 */}
        <KeyPad />
        {/* 확인 */}
        <NextButton
          className={'auto'}
          style={{width: 678, marginTop: 50}}
          label="확인"
          onClick={() => {
            navigate(MENU.CHARGE_START)
          }}
        />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 678px;
  margin: 0 auto;
`
