/**
 * @name 충전현황
 * @description
 */
import {Flex, ImageButton, Text, addComma, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {charge_status_img} from 'assets/svg'
import {COLOR} from 'styles'
import {MENU} from 'config'
// contents
import Title from './contents/title'
import Progress from './contents/progress'
import {NextButton} from 'pages/common/components'
import {useAppDispatch, useAppSelector} from 'model'
import {contextAction} from 'model/modules'

export default function Index() {
  // hooks
  const dispatch = useAppDispatch()
  const {navigate} = useRoutes()
  const {result} = useAppSelector(state => state.context)

  //*--------------------------------------------------*
  return (
    <App gnb={{prev: false, title: '충전 현황', home: true}}>
      <Content>
        {/* 타이틀 */}
        <Title />
        {/* 프로그레스바 */}
        <Progress />
        <button
          className="info"
          onClick={() => {
            dispatch(contextAction.setInit())
            navigate(MENU.CHARGE_DIRECTION)
          }}>
          <Flex.SpaceBetween width="100%" style={{marginBottom: 10}}>
            <Text mode="Button_SB" label="충전시간" color={COLOR.blue10} />
            <Text mode="Button_SB" label={`1시간 00 분`} color={COLOR.blue10} />
          </Flex.SpaceBetween>
          <Flex.SpaceBetween width="100%" style={{marginBottom: 10}}>
            <Text mode="Button_SB" label="충전량" color={COLOR.blue10} />
            <Text mode="Button_SB" label={`${addComma(result?.kwh)} kWh`} color={COLOR.blue10} />
          </Flex.SpaceBetween>
          <Flex.SpaceBetween width="100%" style={{marginBottom: 10}}>
            <Text mode="Button_SB" label="예상 충전 소요시간" color={COLOR.blue10} />
            <Text mode="Button_SB" label={`20 분`} color={COLOR.blue10} />
          </Flex.SpaceBetween>
          <Flex.SpaceBetween width="100%">
            <Text mode="Button_SB" label="예상 결제(충전) 금액" color={COLOR.blue10} />
            <Text mode="Button_SB" label={`${addComma(result?.price)} 원`} color={COLOR.blue10} />
          </Flex.SpaceBetween>
        </button>
        {/* <ImageButton
          onClick={() => {
            dispatch(contextAction.setInit())
            navigate(MENU.CHARGE_DIRECTION)
          }}
          src={charge_status_img}
          width={830}
          style={{marginTop: 60}}
        /> */}
        {/* 확인 */}
        <NextButton
          className={'auto'}
          style={{width: 678, marginTop: 50, backgroundColor: COLOR.red10}}
          label="충전 그만하기"
          onClick={() => {
            navigate(MENU.CHARGE_STOP)
          }}
        />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 678px;
  margin: 0 auto;
  /* section */
  .info {
    width: 678px;
    margin-top: 30px;
    /* margin-bottom: 60px; */
    padding: 40px;
    border-radius: 40px;
    border: 2px solid ${COLOR.gs50};
    background: ${COLOR.white};
    align-items: center;
    gap: 24px;
  }
`
