/**
 * @name 키패드타이틀
 * @description
 */
import {Flex, Loop, Text, addComma} from 'lib'
import {useAppDispatch, useAppSelector} from 'model'
import {contextAction} from 'model/modules'
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {ListRenderItem} from 'types'

export default function Index() {
  // hooks
  const dispatch = useAppDispatch()
  const {chargePrice} = useAppSelector(state => state.context)
  const [price, setPrice] = useState<string>(chargePrice)
  const key = [
    {label: '1,000', sub: '원', value: 768},
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '5,000', sub: '원', value: 5000},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '10,000', sub: '원', value: 7680},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '완충', value: 50000},
    {label: '초기화', value: 'reset'},
    {label: '0', value: 0},
    {label: '지움', value: 'back'},
  ]
  const keypadFunc = (value: any) => {
    if (typeof value === 'number' && ![768, 5000, 7680, 50000].includes(value)) {
      setPrice(prevPrice => prevPrice + String(value)) // 일반 숫자 추가
    } else if (value === 768) {
      setPrice(prevPrice => String(Number(prevPrice) + 768)) // 1,000 추가
    } else if (value === 5000) {
      setPrice(prevPrice => String(Number(prevPrice) + 5000)) // 5,000 추가
    } else if (value === 7680) {
      setPrice(prevPrice => String(Number(prevPrice) + 7680)) // 10,000 추가
    } else if (value === 'reset') {
      setPrice('000') // 초기화
    } else if (value === 'back' && price.length > 0) {
      if (price === '000') {
        setPrice('0')
      }
      setPrice(prevPrice => prevPrice.slice(0, -1)) // 마지막 숫자 제거
    } else if (value === 50000) {
      // 완충
      setPrice('50000')
    }
  }
  useEffect(() => {
    dispatch(contextAction.setPrice(price))
  }, [price])
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex style={{width: '100%', marginTop: 56}} flexdirection="column" alignitems="flex-start" justifycontent="center">
        <Text mode="Body_LSB" color={COLOR.gs10} label="충전 금액(원)" />
        {/* 출력값 */}
        <Flex className="amount" alignitems="flex-end" justifycontent="flex-end">
          <Text mode="Headline_SB" color={price === '' || price === '000' || price === '0' ? COLOR.gs40 : COLOR.gs10} label={price === '000' ? ',000' : addComma(price)} />
          <Text mode="Headline_SB" color={price === '' || price === '000' || price === '0' ? COLOR.gs40 : COLOR.gs10} label="원" style={{marginLeft: 24}} />
        </Flex>
      </Flex>
      <div className="key-wrapper">
        <Loop
          info={key}
          renderItem={({item}: ListRenderItem) => {
            return (
              <button
                className="item"
                onClick={() => {
                  keypadFunc(item.value)
                }}>
                <span className="label">{item.label}</span>
                {item?.sub && <span className="sub">{item.sub}</span>}
              </button>
            )
          }}
        />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  .amount {
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid ${COLOR.gs40};
  }
  .key-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-gap: 16px; */
    grid-row-gap: 8px;
    grid-column-gap: 11px;
    /* 버튼영역,키패드 */
    .item {
      /* width: 192px; */
      height: 70px;
      border-radius: 24px;
      color: ${COLOR.gs10};
      background-color: ${COLOR.blue50};
      outline: none;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 60px */
      letter-spacing: -0.4px;
      -webkit-tap-highlight-color: transparent; /* for removing the highlight */
      .sub {
        display: inline-block;
        margin-left: 5px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
        letter-spacing: -0.4px;
      }
      &:active {
        color: ${COLOR.white} !important;
        background-color: ${COLOR.blue30};
      }
    }
  }
`
