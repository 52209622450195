/**
 * @name 충전금액입력
 * @description
 */
import {useEffect} from 'react'
import {Image, Text, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
// contents
import {DELAY_TIME, MENU} from 'config'
import {charge_wait_img1} from 'assets/images'

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  useEffect(() => {
    setTimeout(() => {
      navigate(MENU.CHARGE_CONNECT)
    }, DELAY_TIME)
  }, [])
  //*--------------------------------------------------*
  return (
    <App
      gnb={{
        prev: true,
        title: '충전 대기중',
        home: false,
      }}>
      <Content>
        <Text align="center" mode="Headline_SB" label="차량에 커넥터를 연결하면 충전을 시작합니다." />
        <Image src={charge_wait_img1} width={600} style={{marginTop: 64}} />
        {/* 확인 */}
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 768px;
  margin: 64 auto;
  padding-top: 54px;
  text-align: center;
`
