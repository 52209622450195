/**
 * @name Top
 */
import React from 'react'
import styled from 'styled-components'
import {Flex, Image, ImageButton, Text, useRoutes} from 'lib'
import {top_company_logo, top_logo} from 'assets/svg'
import {MENU} from 'config'

export default function Top() {
  // hooks
  const {navigate} = useRoutes()
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 전체메뉴 */}
      <Flex className="wrap" alignitems="center" justifycontent="space-between">
        <Flex alignitems="center">
          <ImageButton
            onClick={() => {
              navigate(MENU.NOTICE)
            }}
            width={24}
            height={24}
            src={top_company_logo}
          />
          <Text mode={'Caption_LR'} label="현대백화점 무역 센터점 서울특별시 강남구 테헤란로 517 " color="#183B56" style={{marginLeft: 16, lineHeight: '28px', fontSize: 17}} />
        </Flex>
        <Image src={top_logo} width={171} height={30} />
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: block;
  .wrap {
    width: 100%;
    height: 63px;
    padding: 0 45px;
  }
`
