/**
 * @name Footer
 * @description
 */
import {Dayjs, Flex, Text, useInterval} from 'lib'
import {useState} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'

export default function Contents() {
  // hooks
  const [nowTime, setNowTIme] = useState<string>()

  useInterval(() => {
    const day = Dayjs().format('MM.DD / HH:mm:ss')
    setNowTIme(day)
  }, 768 * 1)

  //*--------------------------------------------------*
  return (
    <Content>
      <Flex className="wrap" justifycontent="space-between" alignitems="center">
        <button
          onClick={() => {
            // Events.emit(CONTEXT_EVENT.DISPLAY_FULL_SCREEN)
          }}>
          <Flex justifycontent="center" alignitems="center">
            <Text mode="Caption_MR" label="고객센터 1600-2693" color={COLOR.gs40} style={{marginRight: 26}} />
            <Text mode="Caption_MR" label="주식회사 크로커스" color={COLOR.gs40} />
          </Flex>
        </button>
        <button
          onClick={() => {
            // Events.emit(CONTEXT_EVENT.DISPLAY_NORMAL_SCREEN)
          }}>
          <Flex className="info">
            <Text mode="Caption_MR" label={nowTime} color={COLOR.gs40} style={{marginRight: 26}} />
            {/* <Text mode="Caption_MR" label="서비스 이용약관" color={COLOR.gs40} style={{marginRight: 26}} /> */}
            <Text mode="Caption_MR" label="Ver. 1.3.1" color={COLOR.gs40} />
          </Flex>
        </button>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  // styles
  width: 100%;
  .wrap {
    height: 45px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  background-color: #183b56;
`
